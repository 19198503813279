import "./App.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PrivacyPolicy from "./PrivacyPolicy";
import Home from "./Home";

function App() {
  useEffect(() => {
    document.title = "Westfield Preservation Alliance";
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
