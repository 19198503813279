import React from "react";

export default function PrivacyPolicy() {
  window.location.href = "/privacypolicy.html";
  return (
    <div className="App">
      <header className="App-header">
        <p>Privacy policy for WPA's app, U R Here-Historic Westfield IN</p>
      </header>
    </div>
  );
}
