import React from "react";
import { SocialIcon } from "react-social-icons";

export default function PrivacyPolicy() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={require("./wpaLogo_50_1_50.png")}
          className="App-logo"
          alt="logo"
        />
        <p style={{ padding: 0, margin: 0 }}>
          🚧 Website under construction 🚧
        </p>
        <p>In the meantime, check out our social media...</p>
        <SocialIcon
          url="https://www.facebook.com/historicalpreservation/"
          target="_blank"
        />
        <p>
          Email us at{" "}
          <a
            style={{ color: "blue" }}
            href="mailto: 
contact.WPA46074@gmail.com"
          >
            contact.WPA46074@gmail.com
          </a>
        </p>
      </header>
    </div>
  );
}
